import { BirthArray } from "@/interface/catalog/CatalogInterFaces";
import { ContractDateCalculator } from "@/services/catalog/ContractDateCalculator";
import { ContractAgeCalculator } from "@/services/global/ContractAgeCalculator";
import { AbstractMessages } from "@/utils/message/AbstractMessages";
import { CatalogInternalCodeEnum } from "@/utils/message/num/CatalogInternalCodeEnum";
import { InternalCodeEnum } from "@/utils/message/num/InternalCodeEnum";
import { LeadInternalCodeEnum } from "@/utils/message/num/LeadInternalCodeEnum";
import { MaildeliveryInternalCodeEnum } from "@/utils/message/num/MaildeliveryInternalCodeEnum";
import { ReserveInternalCodeEnum } from "@/utils/message/num/ReserveInternalCodeEnum";

/**
 * バリデーションの結果
 */
interface ValidationResult {
  // バリデーション項目
  readonly inputName: string;
  // エラーコード
  readonly internalCode: string;
  // バリデーション結果
  readonly isSuccess: boolean;
  // バリデーションメッセージ
  readonly message: string;
}
export class CommonValidater {
  private static forbiddenCharaList =
    /^[纊褜鍈銈蓜俉炻昱棈鋹曻彅丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏塚增墲夋奓奛奝奣妤妺孖寀甯寘寬尞岦岺峵崧嵓﨑嵂嵭嶸嶹巐弡弴彧德忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻桒柀栁桄棏﨓楨﨔榘槢樰橫橆橳橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵濵瀅瀇瀨炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珉珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠緖繒罇羡羽茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞都鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒隆﨩隝隯霳霻靃靍靏靑靕顗顥飯飼餧館馞驎髙髜魵魲鮏鮱鮻鰀鵰鵫鶴鸙黑]+$/;
  /**
   * 禁止文字が含まれているかチェックします
   *
   * @param text
   * @returns
   */
  private static forbiddenCharaCheck(text: string) {
    // 文字列を1文字ずつに分割する
    const str = text.split("");
    // 文字数分ループを回す
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(this.forbiddenCharaList)) {
        return false;
      }
    }
    return true;
  }

  /**
   * メールアドレスのフォーマットチェックを行います
   *
   * @param email
   * @returns
   */
  private static mailAddressFormatCheck(email: string): boolean {
    // メールアドレスチェック
    const pattern =
      /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (email && !pattern.test(email)) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param type
   * @returns typeに対応する各InternalCodeEnumを返却する
   */
  private static getInternalCodeEnum(type: string): any {
    switch (type) {
      case "catalog":
        return CatalogInternalCodeEnum;
      case "lead":
        return LeadInternalCodeEnum;
      case "mailDelivery":
        return MaildeliveryInternalCodeEnum;
      case "reserve":
        return ReserveInternalCodeEnum;
      default:
        return InternalCodeEnum;
    }
  }

  /**
   *
   * @param familyName :漢字姓
   * @param firstName 漢字名
   * @param messagesClass メッセージ作成用クラス
   * @param errorCodeType InternalCodeのtype
   * @returns 漢字指名バリデーション結果
   */
  public static validateKanjiName(
    familyName: string,
    firstName: string,
    messagesClass: AbstractMessages,
    errorCodeType: string
  ): Array<ValidationResult> {
    const validationResultList = new Array<ValidationResult>();
    const internalCodeEnum: any = this.getInternalCodeEnum(errorCodeType);
    if (!familyName) {
      validationResultList.push({
        inputName: "input_name",
        internalCode: internalCodeEnum.FamilyNameEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.FamilyNameEmptyError
        ),
      });
    } else {
      if (
        !familyName.match(/^[ぁ-んー・ァ-ヶ一-龠々]+$/) ||
        !this.forbiddenCharaCheck(familyName)
      ) {
        validationResultList.push({
          inputName: "input_name",
          internalCode: internalCodeEnum.FamilyNameFormatError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FamilyNameFormatError
          ),
        });
      }
      if (familyName.length > 14) {
        validationResultList.push({
          inputName: "input_name",
          internalCode: internalCodeEnum.FamilyNameCountOverError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FamilyNameCountOverError
          ),
        });
      }
    }
    // お名前(名)
    if (!firstName) {
      validationResultList.push({
        inputName: "input_name",
        internalCode: internalCodeEnum.FirstNameEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.FirstNameEmptyError
        ),
      });
    } else {
      if (
        !firstName.match(/^[ぁ-んー・ァ-ヶ一-龠々]+$/) ||
        !this.forbiddenCharaCheck(firstName)
      ) {
        validationResultList.push({
          inputName: "input_name",
          internalCode: internalCodeEnum.FirstNameFormatError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FirstNameFormatError
          ),
        });
      }
      if (firstName.length > 9) {
        validationResultList.push({
          inputName: "input_name",
          internalCode: internalCodeEnum.FirstNameCountOverError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FirstNameCountOverError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param familyNameKana カナ姓
   * @param firstNameKana カナ名
   * @param messagesClass メッセージ作成用クラス
   * @param errorCodeType InternalCodeのtype
   * @returns カナ姓名バリデーション結果
   */
  public static validateKanaName(
    familyNameKana: string,
    firstNameKana: string,
    messagesClass: AbstractMessages,
    errorCodeType: string
  ): Array<ValidationResult> {
    const internalCodeEnum: any = this.getInternalCodeEnum(errorCodeType);
    const validationResultList = new Array<ValidationResult>();
    // お名前(セイ)
    if (!familyNameKana) {
      validationResultList.push({
        inputName: "input_name_kana",
        internalCode: internalCodeEnum.FamilyNameKanaEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.FamilyNameKanaEmptyError
        ),
      });
    } else {
      if (!familyNameKana.match(/^[ァ-ヶー・]+$/)) {
        validationResultList.push({
          inputName: "input_name_kana",
          internalCode: internalCodeEnum.FamilyNameKanaFormatError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FamilyNameKanaFormatError
          ),
        });
      }
      if (familyNameKana.length > 30) {
        validationResultList.push({
          inputName: "input_name_kana",
          internalCode: internalCodeEnum.FamilyNameKanaCountOverError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FamilyNameKanaCountOverError
          ),
        });
      }
    }
    // お名前(メイ)
    if (!firstNameKana) {
      validationResultList.push({
        inputName: "input_name_kana",
        internalCode: internalCodeEnum.FirstNameKanaEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.FirstNameKanaEmptyError
        ),
      });
    } else {
      if (!firstNameKana.match(/^[ァ-ヶー・]+$/)) {
        validationResultList.push({
          inputName: "input_name_kana",
          internalCode: internalCodeEnum.FirstNameKanaFormatError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FirstNameKanaFormatError
          ),
        });
      }
      if (firstNameKana.length > 20) {
        validationResultList.push({
          inputName: "input_name_kana",
          internalCode: internalCodeEnum.FirstNameKanaCountOverError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.FirstNameKanaCountOverError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param birthArray 誕生日配列
   * @param messagesClass メッセージ作成用クラス
   * @param errorCodeType InternalCodeのtype
   * @returns 誕生日バリデーション結果
   */
  public static validateBirthDay(
    birthArray: BirthArray,
    messagesClass: AbstractMessages,
    errorCodeType: string
  ): Array<ValidationResult> {
    // エラーコード
    const internalCodeEnum: any = this.getInternalCodeEnum(errorCodeType);
    // エラーオブジェクト
    const validationResultList = new Array<ValidationResult>();
    // 誕生年
    const birthYear: number = birthArray.birthYear;
    // 誕生月
    const birthMonth: number = birthArray.birthMonth;
    // 誕生日
    const birthDay: number = birthArray.birthDay;
    // 本日の日付
    const currentDate = new Date();
    // 契約基準日
    const contractDate: Date =
      ContractDateCalculator.calculationContactDate(currentDate);
    // 契約年齢
    const contractAge: number = ContractAgeCalculator.calculationContactAge(
      birthYear,
      birthMonth,
      birthDay,
      contractDate
    );
    // 生年月日(年)
    if (!birthYear) {
      validationResultList.push({
        inputName: "select_birth_date",
        internalCode: internalCodeEnum.BirthYearEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.BirthYearEmptyError
        ),
      });
    }
    // 生年月日(月)
    if (!birthMonth) {
      validationResultList.push({
        inputName: "select_birth_date",
        internalCode: internalCodeEnum.BirthMonthEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.BirthMonthEmptyError
        ),
      });
    } else {
      if (birthMonth < 1 || birthMonth > 12) {
        validationResultList.push({
          inputName: "select_birth_date",
          internalCode: internalCodeEnum.BirthMonthOutOfRangeError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.BirthMonthOutOfRangeError
          ),
        });
      }
    }
    // 生年月日(日)
    if (!birthDay) {
      validationResultList.push({
        inputName: "select_birth_date",
        internalCode: internalCodeEnum.BirthDayEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.BirthDayEmptyError
        ),
      });
    } else {
      if (birthDay < 1 || birthDay > 31) {
        validationResultList.push({
          inputName: "select_birth_date",
          internalCode: internalCodeEnum.BirthDayOutOfRangeError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.BirthDayOutOfRangeError
          ),
        });
      }
    }

    // 生年月日の全てが選択されている場合
    if (birthYear && birthMonth && birthDay) {
      // 契約年齢
      if (contractAge > 70 || contractAge < 18) {
        validationResultList.push({
          inputName: "select_birth_date",
          internalCode: internalCodeEnum.AgeRestrictedError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.BirthDayOutOfRangeError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param primaryPhone 電話番号
   * @param messagesClass メッセージ作成用クラス
   * @param errorCodeType InternalCodeのtype
   * @returns 電話番号バリデーション結果
   */
  public static validatePhoneNumber(
    primaryPhone: string,
    messagesClass: AbstractMessages,
    errorCodeType: string
  ): Array<ValidationResult> {
    const internalCodeEnum: any = this.getInternalCodeEnum(errorCodeType);
    const validationResultList = new Array<ValidationResult>();
    // 電話番号
    if (!primaryPhone) {
      validationResultList.push({
        inputName: "input_primary_phone",
        internalCode: internalCodeEnum.PrimaryPhoneEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.PrimaryPhoneEmptyError
        ),
      });
    } else {
      if (primaryPhone.length > 11) {
        validationResultList.push({
          inputName: "input_primary_phone",
          internalCode: internalCodeEnum.PrimaryPhoneCountOverError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.PrimaryPhoneCountOverError
          ),
        });
      }
      if (!primaryPhone.match(/^0\d{9,10}$/)) {
        validationResultList.push({
          inputName: "input_primary_phone",
          internalCode: internalCodeEnum.PrimaryPhoneFormatError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.PrimaryPhoneFormatError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param insuredPersonGender 性別
   * @param messagesClass メッセージ作成用クラス
   * @param errorCodeType InternalCodeのtype
   * @returns 性別バリエーション結果
   */
  public static validateSex(
    insuredPersonGender: string,
    messagesClass: AbstractMessages,
    errorCodeType: string
  ): Array<ValidationResult> {
    const internalCodeEnum: any = this.getInternalCodeEnum(errorCodeType);
    const validationResultList = new Array<ValidationResult>();
    // 性別
    if (!insuredPersonGender) {
      validationResultList.push({
        inputName: "b04_00",
        internalCode: internalCodeEnum.InsuredPersonGenderEmptyError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.InsuredPersonGenderEmptyError
        ),
      });
    } else {
      if (insuredPersonGender != "1" && insuredPersonGender != "2") {
        validationResultList.push({
          inputName: "b04_00",
          internalCode: internalCodeEnum.InsuredPersonGenderInputError,
          isSuccess: false,
          message: messagesClass.generateMessage(
            internalCodeEnum.InsuredPersonGenderInputError
          ),
        });
      }
    }
    return validationResultList;
  }

  /**
   *
   * @param email Emailアドレス
   * @param messagesClass メッセージ作成用クラス
   * @param errorCodeType InternalCodeのtype
   * @returns Emailアドレスバリデーション結果
   */
  public static validateEmail(
    email: string,
    messagesClass: AbstractMessages,
    errorCodeType: string
  ): Array<ValidationResult> {
    const internalCodeEnum: any = this.getInternalCodeEnum(errorCodeType);
    const validationResultList = new Array<ValidationResult>();
    // メールアドレスのチェック
    if (email.length > 80 || !this.mailAddressFormatCheck(email)) {
      validationResultList.push({
        inputName: "input_email",
        internalCode: internalCodeEnum.MailAddressFormatError,
        isSuccess: false,
        message: messagesClass.generateMessage(
          internalCodeEnum.MailAddressFormatError
        ),
      });
    }
    return validationResultList;
  }
}
