<template>
  <div class="l-base">
    <div class="Box-flame">
      <div class="Box-flame__block">
        <h2 class="Block-ttl">パンフレットを今すぐお手元で確認したい方へ</h2>
        <h2 class="Block-ttl-sp">
          パンフレットを今すぐ<br />お手元で確認したい方へ
        </h2>
      </div>
      <div class="Box-flame__block">
        <p class="Notes Notes--mb">
          お急ぎの方は、PDF版パンフレットをダウンロードしていただけます。
        </p>
        <div class="Btn-area">
          <div class="Btn-area__item">
            <div class="Btn-area__btn">
              <a href="/pdf/lifenet_PDF_brochure.pdf" target="_blank">
                <button
                  type="button"
                  class="Btn Btn--type01"
                  @click="pdfDownloadTime()"
                >
                  PDF版をダウンロードする
                </button>
              </a>
            </div>
          </div>
        </div>
        <p class="Notes">
          PDF形式のファイルをご覧いただくには「Adobe Acrobat
          Reader」が必要です。 「Adobe Acrobat
          Reader」は、米国およびその他の国におけるアドビ社の登録商標または商標です。
        </p>
        <p class="Notes">
          <a href="https://get.adobe.com/jp/reader/" target="_blank"
            ><img
              src="../../../assets/Get-Adobe-Acrobat-Reader-badge.png"
              alt="Get Adobe Acrobat Reader"
              width="159"
              height="39"
          /></a>
        </p>
      </div>
    </div>
    <!--/.Box-flame-->
    <div class="Box-flame">
      <div class="Box-flame__block">
        <h2 class="Block-ttl">パンフレットを複数ご希望の方へ</h2>
        <h2 class="Block-ttl-sp">パンフレットを複数<br />ご希望の方へ</h2>
      </div>
      <div class="Box-flame__block">
        <p class="Notes Notes--mb">
          ご家族やご友人、知人の方へなどパンフレットをご希望の方は、以下のボタンからもう1セットご請求ください。<br />住所などの必要情報が引き継がれますので、かんたんに資料をご請求いただけます。
        </p>
        <div class="Btn-area">
          <div class="Btn-area__item">
            <div class="Btn-area__btn">
              <button
                type="button"
                @click="applyAgain()"
                class="Btn Btn--type01"
              >
                もう1セットパンフレットを請求する
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--/mbox-->
    </div>
    <!--/.Box-flame-->
    <!-- 入力、確認と少しレイアウト違うため切り分けどうするか相談 -->
    <div class="Box-flame">
      <div class="Box-flame__block">
        <h2 class="Block-ttl">保険相談サービスのご案内</h2>
        <h2 class="Block-ttl-sp">保険相談サービスのご案内</h2>
      </div>
      <div class="Box-flame__block">
        <p class="Notes">
          あなたのライフスタイルや将来設計に合わせた保険選びをお手伝いします。もちろん、通話料、相談料は無料です。
        </p>
        <div class="Contact-area">
          <div class="Contact-area__notes">
            <div class="Contact-info">
              <p class="Contact-info__ttl">電話での保険相談</p>
              <div class="Contact-info__inner">
                <p class="Contact-info__tel">
                  <img
                    src="../../../assets/ico_tel.svg"
                    alt="フリーダイヤル"
                    width="68"
                    height="38"
                    class="Contact-info__ico"
                  />
                  <img
                    src="../../../assets/ico_tel_sp.svg"
                    alt="フリーダイヤル"
                    width="68"
                    height="38"
                    class="Contact-info__ico-sp"
                  />0120-205566
                </p>
                <p class="Contact-info__txt">無料・携帯OK</p>
              </div>
            </div>
            <div class="Contact-msg">
              <p class="Contact-msg__item">
                受付時間:平日9時～20時、土日祝9時～18時（年末年始除く）<br />日・祝は保険相談のみの受付となります。
              </p>
              <p class="Contact-msg__item">
                お客さまからのご質問やご要望などを正確に把握するため、通話内容を録音しております。
              </p>
            </div>
          </div>
          <div class="Contact-area__item">
            <img
              src="../../../assets/img_contact01.jpg"
              alt=""
              width="164"
              height="185"
              class="Contact-area__img"
            />
          </div>
        </div>
        <div class="Btn-area">
          <p class="Btn-area__txt">
            いま、電話でお話しする時間がとれない方は、ご希望の日時を指定いただくと、保険プランナーよりお客さまにお電話をさしあげます。
          </p>
          <div class="Btn-area__item">
            <p class="Btn-area__btn">
              <a
                href="https://frm.lifenet-seimei.co.jp/reserve/"
                data-prop44="[click_pc]catalog_thanks_cc_reserve"
                class="Btn Btn--type01"
                >保険相談予約をする</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- ライフネット生命の保険商品 -->
    <!-- 将来的に復活させる予定なので、その場合は以下コメントアウトを解除する。 -->
    <!-- <div class="Box-flame">
      <div class="Box-flame__block">
        <h2 class="Block-ttl">ライフネット生命の保険商品</h2>
        <h2 class="Block-ttl-sp">ライフネット生命の保険商品</h2>
      </div>

      <ul class="l-products">
        <li class="l-products__3item Commodity-box">
          <img
            src="../../../assets/img_commodity01_01.png"
            alt="定期死亡保険「かぞくへの保険」"
            width="272px"
            height="106px"
          />
          <p class="Commodity-box__txt">
            お手頃な保険料で<br />万が一のときに備える保険
          </p>

          <a
            href="https://www.lifenet-seimei.co.jp/product/life/"
            class="Commodity-box__btn Commodity-box__btn--type01"
            >商品の内容を見る</a
          >
          <a
            href="https://www.lifenet-seimei.co.jp/product/life/"
            class="Commodity-box__btn-sp Commodity-box__btn--type01"
            >商品を詳しく見る</a
          >
        </li>
        <li class="l-products__3item Commodity-box">
          <img
            src="../../../assets/img_commodity06.png"
            alt="終身医療保険「じぶんへの保険3」"
            width="272px"
            height="106px"
          />
          <p class="Commodity-box__txt">
            入院・手術に備えられる医療保険<br />保険料は一生涯上がりません
          </p>

          <a
            href="https://www.lifenet-seimei.co.jp/product/medical/"
            class="Commodity-box__btn Commodity-box__btn--type03"
            >商品の内容を見る</a
          >
          <a
            href="https://www.lifenet-seimei.co.jp/product/medical/"
            class="Commodity-box__btn-sp Commodity-box__btn--type03"
            >商品を詳しく見る</a
          >
        </li>
        <li class="l-products__3item Commodity-box">
          <img
            src="../../../assets/img_commodity07.png"
            alt="終身医療保険「じぶんへの保険3レディース」"
            width="272px"
            height="106px"
          />
          <p class="Commodity-box__txt">
            女性特有の病気に手厚い医療保険<br />保険料は一生涯上がりません
          </p>

          <a
            href="https://www.lifenet-seimei.co.jp/product/medicalwomen/"
            class="Commodity-box__btn Commodity-box__btn--type04"
            >商品の内容を見る</a
          >
          <a
            href="https://www.lifenet-seimei.co.jp/product/medicalwomen/"
            class="Commodity-box__btn-sp Commodity-box__btn--type04"
            >商品を詳しく見る</a
          >
        </li>
        <li class="l-products__3item Commodity-box">
          <img
            src="../../../assets/img_commodity02_01.png"
            alt="がん保険「ダブルエール」"
            width="272px"
            height="106px"
          />
          <p class="Commodity-box__txt">
            がん診断から治療まで幅広くサポート<br />ニーズに合わせて選べる保険
          </p>
          <a
            href="https://www.lifenet-seimei.co.jp/product/cancer/"
            class="Commodity-box__btn Commodity-box__btn--type02"
            >商品の内容を見る</a
          >
          <a
            href="https://www.lifenet-seimei.co.jp/product/cancer/"
            class="Commodity-box__btn-sp Commodity-box__btn--type02"
            >商品を詳しく見る</a
          >
        </li>
        <li class="l-products__3item Commodity-box">
          <img
            src="../../../assets/img_commodity08.png"
            alt="就業不能保険「働く人への保険3」"
            width="272px"
            height="106px"
          />
          <p class="Commodity-box__txt">
            病気やケガで長期間働けないときの<br />収入減少に備える保険
          </p>
          <a
            href="https://www.lifenet-seimei.co.jp/product/disability/"
            class="Commodity-box__btn Commodity-box__btn--type05"
            >商品の内容を見る</a
          >
          <a
            href="https://www.lifenet-seimei.co.jp/product/disability/"
            class="Commodity-box__btn-sp Commodity-box__btn--type05"
            >商品を詳しく見る</a
          >
        </li>
        <li class="l-products__3item Commodity-box">
          <img
            src="../../../assets/img_commodity09.png"
            alt="認知症保険「be」"
            width="272px"
            height="106px"
          />
          <p class="Commodity-box__txt">
            認知症や軽度認知障害に備えて<br />経済的不安を減らせる保険
          </p>
          <a
            href="https://www.lifenet-seimei.co.jp/product/dementia/"
            class="Commodity-box__btn Commodity-box__btn--type06"
            >商品の内容を見る</a
          >
          <a
            href="https://www.lifenet-seimei.co.jp/product/dementia/"
            class="Commodity-box__btn-sp Commodity-box__btn--type06"
            >商品を詳しく見る</a
          >
        </li>
      </ul>
    </div> -->
    <!--/.Box-flame-->
    <div class="Control-back">
      <div class="Btn-area">
        <div class="Btn-area__item">
          <p class="Btn-area__btn">
            <a
              href="https://www.lifenet-seimei.co.jp"
              data-prop44="[click_pc]catalog_thanks_back_top"
              class="Btn Btn--type01"
              >トップページへ戻る</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script src="./CatalogGuidanceComplete.ts" />

<style scoped>
.Btn-area__btn a {
  text-decoration: none;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.Page-ttl,
.Block-ttl {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
.Btn--type01::before {
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -35px;
}
.Btn::before {
  border-radius: 50%;
  background: #3f5d26;
}
.Btn::before,
.Btn::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
}
.Btn::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #f1f3ee;
}
.Btn--type01::after {
  left: 100%;
  margin: -5px 0 0 -28px;
  border-width: 5px 0 5px 8px;
  color: #ffffff;
}
sup {
  top: -0.5em;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
img {
  vertical-align: middle;
  border-style: none;
}
.Box-flame + .Box-flame {
  margin-top: 20px;
}
.Contact-area {
  margin: 30px 0;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  flex-direction: row-reverse;
}
.Contact-area__notes {
  width: 600px;
}
.Contact-info {
  line-height: 1.3;
}
.Contact-info__inner {
  display: flex;
  margin-top: 20px;
  flex-flow: wrap;
  align-items: center;
}
.Contact-msg {
  display: inline-block;
  margin-top: 15px;
  font-size: 1.4rem;
}
.Contact-msg__item + .Contact-msg__item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #666;
}
.Contact-area__item {
  margin-right: 20px;
  text-align: center;
}
.Btn-area__txt {
  margin-bottom: 16px;
  color: #588310;
  font-size: 1.4rem;
}

.Commodity-box__col:first-child {
  font-size: 1.1rem;
  line-height: 1.5;
}
.Commodity-box__col {
  padding: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dl,
ol,
ul {
  margin-top: 0;
}
.Commodity-box__check {
  position: relative;
  margin: 2px 0 14px;
  padding: 0 0 0 30px;
  font-size: 1.4rem;
  line-height: 1.2;
  list-style: none;
}
.Commodity-box__check::before {
  display: block;
  position: absolute;
  top: -3px;
  left: 0;
  width: 21px;
  height: 18px;
  background: url(../../../assets/ico_check.png) no-repeat 0 0;
  background-size: cover;
  content: "";
}
.Control-back {
  margin-top: 60px;
}
@media screen and (min-width: 501px) {
  .Commodity-box__btn-sp {
    display: none;
  }
  .text-center {
    text-align: center;
  }
  .Block-ttl-sp {
    display: none;
  }
  .l-base {
    width: 1040px;
    margin: 0 auto;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fafafa;
    box-shadow: 10px 10px 8px -9px rgb(158 158 158 / 80%);
  }
  .Box-flame__block {
    margin: 48px 99px;
  }

  .Block-ttl {
    padding-bottom: 32px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 3.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    letter-spacing: normal;
  }

  .Notes--mb {
    margin-bottom: 56px;
  }
  .Notes {
    margin: 0 50px 32px;
  }
  .Btn-area {
    width: 740px;
    margin: 25px auto 32px;
  }
  .Btn-area__item {
    width: 440px;
    margin: 0 auto;
  }
  .Btn--type01 {
    height: 72px;
    margin: 0 auto;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -8px rgb(0 16 14 / 31%);
    font-size: 2rem;
  }
  .Btn {
    display: flex;
    position: relative;
    width: 100%;
    padding-right: 35px;
    border: 2px solid #799b3f;
    border-radius: 8px;
    background: #f1f3ee;
    font-weight: bold;
    color: #3f5d26 !important;
    text-align: center;
    text-decoration: none !important;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }

  .Contact-info__ttl {
    margin-bottom: 15px;
    font-size: 2.4rem;
    color: #588310;
    font-weight: bold;
  }

  .Contact-info__tel {
    display: flex;
    margin-right: 16px;
    font-size: 3.6rem;
    font-weight: bold;
    color: #000;
    line-height: 1;
    align-items: flex-end;
  }
  .Contact-info__ico {
    margin-right: 10px;
  }
  .Contact-info__ico-sp {
    display: none;
  }
  .Contact-info__txt {
    display: block;
    padding: 8px 9px 7px;
    border: 1px solid #666;
    border-radius: 4px;
    text-align: center;
    font-size: 1.8rem;
  }

  .l-products {
    display: flex;
    width: 960px;
    margin: -20px auto 48px;
    padding: 0;
    flex-flow: wrap;
  }
  .l-products__2item {
    width: calc((100% - 40px) / 2);
    margin: 20px 10px 0;
  }
  .Commodity-box {
    padding: 19px 0;
    border: 1px solid #d0d4d9;
    border-radius: 8px;
    text-align: center;
    list-style: none;
    letter-spacing: normal;
  }
  .Commodity-box__btn {
    display: flex;
    position: relative;
    margin: 0 auto;
    padding-right: 18px;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    color: #fff !important;
    text-decoration: none !important;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  .Commodity-box__btn--type01 {
    background: #92b729;
  }
  .Commodity-box__btn::before {
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -38px;
    border-radius: 50%;
    background: #fff;
  }
  .Commodity-box__btn::before,
  .Commodity-box__btn::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .Commodity-box__btn::after {
    width: 0;
    height: 0;
    margin: -6px 0 0 -29px;
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent;
  }
  .Commodity-box__btn--type01::after {
    border-left-color: #92b729;
  }

  .Commodity-box__btn--type02 {
    background: #f93828;
  }
  .Commodity-box__btn--type02::after {
    border-left-color: #f93828;
  }
  .Commodity-box__txt {
    margin: 20px 0;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .Commodity-box__btn--type03 {
    background: #f29600;
  }
  .Commodity-box__btn--type01,
  .Commodity-box__btn--type02,
  .Commodity-box__btn--type03,
  .Commodity-box__btn--type04,
  .Commodity-box__btn--type05,
  .Commodity-box__btn--type06 {
    width: calc(100% - (19px * 2));
    height: 60px;
    font-size: 2rem;
  }
  .Commodity-box__btn--type03::after {
    border-left-color: #f29600;
  }
  .Commodity-box__btn--type04 {
    background: #df2e87;
  }
  .Commodity-box__btn--type04::after {
    border-left-color: #df2e87;
  }
  .Commodity-box__btn--type05 {
    background: #1d59be;
  }
  .Commodity-box__btn--type05::after {
    border-left-color: #1d59be;
  }

  .Commodity-box__btn--type06 {
    background: #aa6ecd;
  }
  .Commodity-box__btn--type06::after {
    border-left-color: #aa6ecd;
  }

  .Commodity-box__inner {
    display: flex;
    width: calc(100% - (28px * 2));
    height: 133px;
    margin: 0 auto;
    text-align: left;
  }

  .l-products__3item {
    width: calc((100% - 60px) / 3);
    margin: 20px calc(20px / 2) 0;
  }

  .products-image-sp {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .Commodity-box__btn {
    display: none;
  }
  .Commodity-box__btn-sp {
    display: flex;
    position: relative;
    margin: 0 auto;
    padding-right: 18px;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    color: #fff !important;
    text-decoration: none !important;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  .Commodity-box__btn--type01 {
    background: #92b729;
  }
  .Commodity-box__btn-sp::before {
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -38px;
    border-radius: 50%;
    background: #fff;
  }
  .Commodity-box__btn-sp::before,
  .Commodity-box__btn-sp::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
  }
  .Commodity-box__btn-sp::after {
    width: 0;
    height: 0;
    margin: -6px 0 0 -29px;
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent;
  }
  .Commodity-box__btn--type01::after {
    border-left-color: #92b729;
  }

  .Commodity-box__btn--type02 {
    background: #f93828;
  }
  .Commodity-box__btn--type02::after {
    border-left-color: #f93828;
  }
  .Commodity-box__txt {
    margin: 20px 0;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .Commodity-box__btn--type03 {
    background: #f29600;
  }
  .Commodity-box__btn--type01,
  .Commodity-box__btn--type02,
  .Commodity-box__btn--type03,
  .Commodity-box__btn--type04,
  .Commodity-box__btn--type05,
  .Commodity-box__btn--type06 {
    width: calc(100% - (19px * 2));
    height: 60px;
    font-size: 2rem;
  }
  .Commodity-box__btn--type03::after {
    border-left-color: #f29600;
  }
  .Commodity-box__btn--type04 {
    background: #df2e87;
  }
  .Commodity-box__btn--type04::after {
    border-left-color: #df2e87;
  }
  .Commodity-box__btn--type05 {
    background: #1d59be;
  }
  .Commodity-box__btn--type05::after {
    border-left-color: #1d59be;
  }
  .Commodity-box__btn--type06 {
    background: #aa6ecd;
  }
  .Commodity-box__btn--type06::after {
    border-left-color: #aa6ecd;
  }

  .l-base {
    width: 100%;
    margin: 0 auto;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fff;
    box-shadow: 10px 10px 8px -9px rgb(158 158 158 / 80%);
    width: 95%;
    margin: 0 auto;
  }
  .Box-flame__block {
    margin: 20px 15px;
  }

  .Block-ttl {
    display: none;
  }
  .Block-ttl-sp {
    padding-bottom: 20px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0;
    text-align: center;
  }

  .Btn-area {
    margin: 20px 10px;
  }
  .Btn-area__item {
    width: 95%;
    margin: 0 auto;
  }

  .Btn--type01 {
    height: 60px;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .Btn {
    display: flex;
    position: relative;
    width: 100%;
    padding: 0 40px 0 20px;
    border: 2px solid #3f5d26;
    border-radius: 8px;
    background: #f4faeb;
    color: #3f5d26 !important;
    text-align: center;
    text-decoration: none !important;
    font-weight: bold;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
  }
  .Cv-btn--type02::after {
    border-left-color: #70ad46;
  }

  .Contact-area__img {
    display: none;
  }

  .Contact-info__ttl {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0;
  }

  .Contact-info__tel {
    display: flex;
    margin: 0 5px 0 0;
    font-size: 2.6rem;
    font-weight: bold;
    color: #0044cc;
    align-items: center;
  }
  .Contact-info__ico {
    display: none;
  }
  .Contact-info__ico-sp {
    width: 35px;
    height: auto;
    margin-right: 5px;
  }
  .Contact-info__txt {
    display: inline-block;
    padding: 3px 2px 1px;
    border: 1px solid #666;
    border-radius: 4px;
    vertical-align: baseline;
    font-size: 1rem;
    text-align: center;
  }

  .l-products {
    display: flex;
    width: 100%;
    margin: -20px auto 48px;
    padding: 0;
    flex-flow: wrap;
    flex-direction: column;
  }
  .l-products__2item {
    /* width: calc((100% - 40px) / 2); */
    margin: 20px 10px 0;
  }
  .l-products__2item img {
    max-width: 73%;
    height: auto;
    margin: 0 auto;
  }
  .Commodity-box {
    padding: 26px 0;
    border: 1px solid #d0d4d9;
    border-radius: 8px;
    text-align: center;
    list-style: none;
    letter-spacing: normal;
    width: 95%;
  }
  .Commodity-box__inner {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
    text-align: left;
  }

  .l-products__3item {
    margin: 20px calc(20px / 2) 0;
  }

  .products-image {
    display: none;
  }
  .Notes {
    margin: 0 10px;
  }
}
</style>
