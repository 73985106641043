<template>
  <!-- 資料請求画面「お電話でのお問い合わせ」～ -->
  <!-- 入力画面、確認画面、完了画面で使用 -->
  <!-- <div class="l-base"> -->
  <div class="Box-flame">
    <div class="Box-flame__block">
      <h3 class="Block-ttl">お電話でのお問い合わせ</h3>
    </div>
    <div class="Contact-area">
      <div class="Contact-area__notes">
        <div class="Contact-info">
          <p class="Contact-info__ttl">コンタクトセンター</p>
          <p>
            ご不明な点がある方やお困りの方は、お気軽にお問い合わせください。
          </p>
          <div class="Contact-info__inner">
            <p class="Contact-info__tel">
              <img
                src="../../../assets/ico_tel.svg"
                alt="フリーダイヤル"
                width="68"
                height="38"
                class="Contact-info__ico"
              />0120-205566
            </p>
            <p class="Contact-info__tel-sp">
              <img
                src="../../../assets/ico_tel_sp.svg"
                alt="フリーダイヤル"
                width="25"
                height="16"
                class="Contact-info__ico"
              /><a class="Contact-info__phone" href="tel:0120-205566"
                >0120-205566</a
              >
            </p>
            <p class="Contact-info__txt">無料・携帯OK</p>
          </div>
        </div>
        <div class="Contact-msg">
          <p class="Contact-msg__item">
            受付時間:平日9時～20時、土日祝9時～18時（年末年始除く）<br />日・祝は保険相談のみの受付となります。
          </p>
          <p class="Contact-msg__item">
            お電話の内容はお客さまからのご質問やご要望などを正確に把握するため、通話内容を録音しておりますので、あらかじめご了承ください。
          </p>
        </div>
      </div>
      <div class="Contact-area__item">
        <img
          src="../../../assets/img_contact01.jpg"
          alt=""
          width="164"
          height="185"
        />
      </div>
    </div>
  </div>
  <!-- /.Box-flame -->
  <!-- </div> -->
  <!-- /.1-Base -->
  <!-- /[[[ LNRUB-MAIN-AREA ]]] -->
</template>
<script lang="ts">
// templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用
import { defineComponent } from "vue";
export default defineComponent({
  name: "CatalogBottom",
});
</script>
<style scoped>
*,
::after,
::before {
  box-sizing: border-box;
}
.Contact-area__notes {
  width: 600px;
}
.Contact-msg__item + .Contact-msg__item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #666;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(102, 102, 102);
}
.Block-ttl {
  padding-bottom: 32px;
  border-bottom: 1px solid #3b5e10;
  color: #3b5e10;
  font-size: 3.4rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.1;
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Hiragino Sans", Meiryo, sans-serif;
}
.Box-flame {
  overflow: hidden;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  background: #fafafa;
  box-shadow: 10px 10px 8px -9px rgb(158 158 158 / 80%);
}

.Box-flame__block {
  margin: 48px 99px;
}

.Contact-area {
  display: flex;
  margin: 20px 40px 48px;
  flex-flow: wrap;
  justify-content: center;
  flex-direction: row-reverse;
}

.Contact-info {
  line-height: 1.3;
}
.Contact-info__ttl {
  margin-bottom: 14px;
  color: #588310;
  font-size: 2rem;
  font-weight: bold;
}
.Contact-info__inner {
  display: flex;
  margin-top: 20px;
  flex-flow: wrap;
  align-items: center;
}

.Contact-info__tel {
  display: flex;
  margin-right: 16px;
  font-size: 3.6rem;
  font-weight: bold;
  color: #000;
  line-height: 1;
  align-items: flex-end;
}
.Contact-info__ico {
  margin-right: 10px;
}
.Contact-info__txt {
  display: block;
  padding: 8px 9px 7px;
  border: 1px solid #666;
  border-radius: 4px;
  text-align: center;
  font-size: 1.8rem;
}
.Contact-msg {
  display: inline-block;
  margin-top: 15px;
  font-size: 1.4rem;
}

.Contact-area__item {
  margin-right: 20px;
  text-align: center;
}

.Contact-info__tel-sp {
  display: none;
}
@media screen and (max-width: 500px) {
  .Block-ttl {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #3b5e10;
    color: #3b5e10;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0;
  }
  .Box-flame {
    overflow: hidden;
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    background: #fafafa;
    box-shadow: 10px 10px 8px -9px rgb(158 158 158 / 80%);
    width: 100%;
  }

  .Box-flame__block {
    margin: 20px 15px;
  }

  .Contact-area {
    display: flex;
    margin: 0 10px 20px;
    flex-flow: wrap;
    justify-content: center;
    flex-direction: row-reverse;
  }

  .Contact-info {
    line-height: 1.3;
    padding: 0 15px 0 15px;
  }
  .Contact-info__ttl {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0;
  }
  .Contact-info__inner {
    display: flex;
    margin-top: 5px;
    line-height: 1.2;
    flex-flow: wrap;
    align-items: center;
  }

  .Contact-info__tel {
    display: none;
  }
  .Contact-info__tel-sp {
    display: flex;
    margin: 0 5px 0 0;
    font-size: 2.6rem;
    font-weight: bold;
    color: #3582b0;
    align-items: center;
    text-decoration: none;
  }
  .Contact-info__ico {
    width: 35px;
    height: auto;
    margin-right: 5px;
  }
  .Contact-info__phone {
    text-decoration: none;
    outline: none;
    -moz-opacity: 0.7;
    opacity: 0.7;
  }
  .Contact-info__txt {
    display: inline-block;
    padding: 3px 2px 1px;
    border: 1px solid #666;
    border-radius: 4px;
    vertical-align: baseline;
    font-size: 1rem;
    text-align: center;
  }
  .Contact-msg {
    display: inline-block;
    margin-top: 10px;
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 0 15px 0 15px;
  }

  .Contact-area__item {
    display: none;
  }
}
</style>
